/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

function validateStoreParams(params) {
    const {freight_id, replace_fiscal_voucher_id} = typeof params === 'object' ?
        params :
        {freight_id: params, replace_fiscal_voucher_id: null}

    if (freight_id === null || freight_id === undefined) {
        throw new Error('freight_id not specified in params!')
    }
    const freightId = Number(freight_id)
    if (Number.isNaN(freightId)) {
        throw new Error(`freight_id value '${freight_id}' is not a number!`)
    }

    return {
        freight_id: freightId,
        replace_fiscal_voucher_id,
    }
}

export const Invoice = {
    async list(freight_id) {
        const response = await axios.get(`/api/freights/${freight_id}/invoices`, null, {responseType: 'json'})

        return response.data.data
    },

    async show(freight_id, invoice_id) {
        const response = await axios.get(`/api/freights/${freight_id}/invoices/${invoice_id}`)

        return response.data.data
    },

    /**
     * Creates a new invoice from the specified freight
     *
     * @param {number|{freight_id:number,replace_fiscal_voucher_id:number}} params
     * @return {Promise<*>}
     */
    async store(params) {
        const {freight_id, replace_fiscal_voucher_id} = validateStoreParams(params)
        const requestData = {replace_fiscal_voucher_id}
        const response = await axios.post(`/api/freights/${freight_id}/invoices`, requestData, {responseType: 'json'})

        return response.data.data
    },
    async update(data) {
        const request_data = {
            vehicle_id: data.vehicle_id,
            trailer_id: data.trailer_id,
            second_trailer_id: data.second_trailer_id,
            vehicle_gross_weight: data.vehicle_gross_weight,
            notes: data.notes,
            addendas: data.addendas,
        }
        await axios.patch(`/api/freights/${data.freight_id}/invoices/${data.invoice_id}`, request_data, {responseType: 'json'})
    },
}

export default Invoice