/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'
import QueryStatusResponse from './QueryStatusResponse'
import relatedFiscalVoucher from './RelatedFiscalVoucher'
import fiscalVoucherCancellationRequest from './fiscalVoucherCancellationRequest'

export const FiscalVoucher = {
    fiscalVoucherCancellationRequest,
    relatedFiscalVoucher,

    async cancel(invoicing_voucher_id) {
        await axios.patch(`/api/invoicing_vouchers/${invoicing_voucher_id}/cancel`)
    },
    async lastStampError(invoicing_voucher_id) {
        const response = await axios.get(`/data/invoicing_vouchers/${invoicing_voucher_id}/last_stamp_error`)
        return response.data
    },
    async nullify(fiscal_voucher_id) {
        await axios.patch(`/api/invoicing_vouchers/${fiscal_voucher_id}/nullify`, null, {responseType: 'json'})
    },
    async queryStatus(invoicing_voucher_id) {
        const response = await axios.get(`/api/invoicing_vouchers/${invoicing_voucher_id}/query_status`)

        return new QueryStatusResponse(response.data.data)
    },
    async relatedFiscalVouchers(invoicing_voucher_id) {
        const response = await axios.get(`/api/invoicing_vouchers/${invoicing_voucher_id}/related_fiscal_vouchers`)

        return response.data.data
    },
}

export default FiscalVoucher